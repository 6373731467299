.toolbar {
  background: black;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 50px;
  padding: 0 10px;
  /* ensure toolbar is on top of share dialog when it slides up/down */
  position: relative;
  z-index: 12;
}

@media (min-width: 600px) {
  .toolbar {
    flex-wrap: nowrap;
    padding: 0;
  }
}

.toolbar__home:hover .icon path {
  fill: #c7a209;
}

@media (min-width: 600px) {
  .toolbar__home {
    padding: 0 15px;
  }
}

.toolbar__track-name {
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  font-family: "Maax", Helvetica, sans-serif;
}

@media (min-width: 800px) {
  .toolbar__track-name {
    font-size: 18px;
    height: 30px;
  }
}

.toolbar__track-details {
  padding: 10px;
  order: 10;
  flex: 1 0 calc(100% - 20px);
  background: #414141;
  display: flex;
  justify-content: space-between;
  /* ensure track details are on top of share dialog when it slides up/down */
  position: relative;
  z-index: 11;
}

.toolbar__shared-track-name {
  color: white;
  font-size: 18px;
  font-family: "Maax", Helvetica, sans-serif;
  display: none;
}

@media (min-width: 800px) {
  .toolbar__shared-track-name {
    line-height: 1;
  }
  .toolbar__shared-track-name {
    display: block;
  }
}

.toolbar__shared-track-author {
  color: #6e6e6e;
  font-size: 18px;
  font-family: "Maax", Helvetica, sans-serif;
}

@media (min-width: 800px) {
  .toolbar__track-details {
    padding: 0 10px;
    flex: initial;
    background: transparent;
    display: block;
  }
}

.toolbar__play {
  margin: 0 10px;
}

@media (min-width: 800px) {
  .toolbar__play {
    margin: 0;
  }
}

.toolbar__play:hover .icon path {
  fill: #c7a209;
}

.toolbar__controls {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

@media (min-width: 600px) {
  .toolbar__controls {
    height: 100%;
    padding: 0 15px;
  }
}

@media (min-width: 800px) {
  .toolbar__controls {
    background: #414141;
  }
}

.toolbar__metronome {
  margin: 0 10px;
}

@media (min-width: 800px) {
  .toolbar__metronome {
    margin: 0;
  }
}

.toolbar__metronome:hover path {
  fill: #c7a209;
}

.toolbar__metronome--active {
  transition: all 400ms ease-in-out;
  animation: metro 600ms infinite;
}

.toolbar__share {
  display: flex;
  align-items: center;
  color: #fad205;
  text-decoration: none;
  justify-content: space-between;
  font-family: "Maax", Helvetica, sans-serif;
  font-size: 16px;
}

.toolbar__share:hover {
  color: #c7a209;
}

.toolbar__share:hover .icon g {
  stroke: #c7a209;
}

@media (min-width: 600px) {
  .toolbar__share {
    padding: 0 10px;
  }
}

@media (min-width: 800px) {
  .toolbar__share {
    height: 100%;
  }
}

.toolbar__share-link-text {
  display: none;
}

@media (min-width: 800px) {
  .toolbar__share-link-text {
    display: inline;
    margin: 0 0 0 5px;
  }
}

.timeline {
  flex: 1;
  position: relative;
  margin: 0 10px;
  height: 10px;
  overflow: hidden;
  display: none;
  border-radius: 4px;
}

@media (min-width: 600px) {
  .timeline {
    display: block;
  }
}

.timeline__track,
.timeline__track-alt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline__track {
  width: 100%;
  height: 10px;
  background-color: #6e6e6e;
  display: none;
}

.timeline__track-alt {
  height: 10px;
  background-color: white;
  border-radius: 4px;
  z-index: 5;
  display: none;
}

.timeline--recording .timeline__track-alt {
  background: #fad205;
}

@media (min-width: 600px) {
  .timeline__track,
  .timeline__track-alt {
    display: block;
  }
}

@keyframes metro {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.jagged {
  display: none;
  background: black;
  height: 100%;
}

.jagged--invert {
  background: #414141;
}

@media (min-width: 800px) {
  .jagged {
    display: inline-block;
  }
}

.bpm {
  display: flex;
  width: 155px;
  align-items: center;
}

@media (min-width: 600px) {
  .bpm {
    width: 170px;
    margin: 0 10px;
  }
}

.bpm__speed-icon {
  margin: 0 5px;
}

.bpm__slow-icon {
  width: 20px;
}

.bpm__fast-icon {
  width: 32px;
}

.bpm__slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background-color: #6e6e6e;
  border-radius: 3px;
}

.bpm__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fad205;
  border: 3px solid #414141;
  cursor: pointer;
}

.bpm__slider::-webkit-slider-thumb:hover {
  background: #c7a209;
}

.bpm__slider::-moz-range-thumb {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fad205;
  border: 3px solid #414141;
  cursor: pointer;
}

.bpm__slider::-moz-range-thumb:hover {
  background: #c7a209;
}

.bpm__value {
  width: 80px;
  height: 30px;
  background: white;
  position: absolute;
  border-radius: 3px;
  text-align: center;
  line-height: 30px;
  font-family: "Maax", Helvetica, sans-serif;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  margin: 20px 0 0 -40px;
}
