@font-face {
  font-family: "Maax";
  src: url("./fonts/Maax-Medium-205TF.woff2") format("woff2");
  unicode-range: U+0020-007F; /* The bare minimum for the English Language */
  font-display: swap;
}

iframe {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

input,
h1,
button {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mq-min-600,
.mq-min-600-flex,
.mq-min-800,
.mq-min-800-flex {
  display: none;
}

@media (min-width: 800px) {
  .mq-min-800 {
    display: block;
  }

  .mq-min-800-flex {
    display: flex;
  }
}

@media (min-width: 800px) {
  .mq-max-800 {
    display: none;
  }
}

@media (min-width: 600px) {
  .mq-min-600 {
    display: block;
  }

  .mq-min-600-flex {
    display: flex;
  }
}

@media (min-width: 600px) {
  .mq-max-600 {
    display: none;
  }
}
