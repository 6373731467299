.grid {
  height: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

@media (min-width: 800px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.body {
  position: relative;
  /* remove toolbar height */
  height: calc(100% - 96px);
}

@media (min-width: 800px) {
  .body {
    /* remove toolbar height */
    height: calc(100% - 50px);
  }
}