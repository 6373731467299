@import "../../mixins/for-size.scss";
@import "../../mixins/stretch.scss";

.sharing-dialog {
  @include stretch;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  transform: translateZ(0);
}

.sharing-dialog-enter {
  background: transparent;

  .sharing-dialog__content {
    transform: translateY(-100%);
  }
}

.sharing-dialog-enter-active {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 400ms;

  .sharing-dialog__content {
    transform: translateY(0);
    transition: transform 200ms ease-out;
  }
}

.sharing-dialog-exit {
  background: rgba(0, 0, 0, 0.3);

  .sharing-dialog__content {
    transform: translateY(0);
  }
}

.sharing-dialog-exit-active {
  background-color: transparent;
  transition: background-color 400ms;

  .sharing-dialog__content {
    transform: translateY(-100%);
    transition: transform 200ms ease-in;
  }
}

.sharing-dialog__content {
  background: white;
  padding: 20px 10px 10px;
  position: relative;

  @include for-size(600up) {
    border: 6px solid black;
    border-top: none;
    max-width: 1024px;
    margin: 0 50px;
    padding: 20px;
  }

  @include for-size(1024up) {
    margin: 0 auto;
  }
}

.sharing-dialog__close {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;

  @include for-size(800up) {
    display: block;
  }
}

.sharing-dialog__heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}

.sharing-dialog__heading {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Maax', Helvetica, sans-serif;
  color: black;
  padding: 0 10px;

  @include for-size(375up) {
    font-size: 26px;
  }

  @include for-size(600up) {
    padding: 0;
  }
}

.sharing-dialog__input-container {
  border-bottom: 2px solid #D8D8D8;
  display: flex;
  justify-content: space-between;
}

.sharing-dialog__input {
  font-size: 13px;
  font-family: 'Maax', Helvetica, sans-serif;
  width: 100%;
  border: none;
  padding: 10px 0;

  @include for-size(600up) {
    font-size: 23px;
    font-weight: 400;
    width: 85%;
    flex: 1;
    margin: 0 10px 0 0;
  }
}

.sharing-dialog__image {
  width: 100%;
  height: auto;
  border: 2px solid #D8D8D8;
  padding: 10px;

  @include for-size(480up) {
    display: none;
  }
}

.sharing-dialog__image--wide {
  display: none;

  @include for-size(480up) {
    display: block;
  }
}

.sharing-dialog__step--first {
  margin: 0 0 40px;
}

/* lifted from walter */
.sharing-dialog__button  {
  $button-height: 44px;
  $button-padding: 30px;
  $border-width: 3px;
  $skew-angle: 6deg;
  $jagged-offset: 5px;

  // sin(x) ~= x for x << 1
  $content-adjust: 0.5 * $button-height * ($skew-angle / 1rad);

  background-color: transparent;
  border: none;
  position: relative;
  display: inline-block;
  outline: none;
  margin: 0;
  height: $button-height;
  padding: 0 $button-padding;

  font-size: 18px;
  font-family: 'Maax', Helvetica, sans-serif;
  text-decoration: none;
  color: black;

  &-text {
    $content-height: $button-height - $jagged-offset + 1px;

    display: block;
    background-color: #ffd200;
    z-index: 1;
    position: relative;
    left: $content-adjust;
    height: $content-height;
    // Offset for optical centering of text
    line-height: $content-height - 2px;
  }

  &:before,
  &:after {
    content: "";
    z-index: 0;
    display: inline-block;
    background-color: #ffd200;
    position: absolute;
    border: $border-width solid #ffd200;
    width: calc(50% - #{$jagged-offset});
    height: 100%;
    transform: skewX(-$skew-angle);
    transition: transform .05s linear;
  }

  &:before {
    top: -$jagged-offset;
    left: 1px;
  }

  &:after {
    bottom: -$jagged-offset;
    right: 1px;
  }

  &:hover {
    $flip-translate-x: 0.8 * $jagged-offset * ($skew-angle / 1rad);
    $flip-translate-y: 0.8 * $jagged-offset;

    &:before {
      transform: skewX(-$skew-angle) translate(-$flip-translate-x, $flip-translate-y);
    }

    &:after {
      transform: skewX(-$skew-angle) translate($flip-translate-x, -$flip-translate-y);
    }
  }

  &:focus {
    &:before,
    &:after {
      border: $border-width solid #ff8232;
    }
  }
}
