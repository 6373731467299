@import "../../mixins/for-size.scss";
@import "../../mixins/stretch.scss";

.recorder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex: 1 0 25%;

  @include for-size(800up) {
    flex-basis: 50%;
  }
}

.recorder:nth-child(1) {
  background-color: #0043ff;
}

.recorder:nth-child(2) {
  background-color: #24e36f;
}

.recorder:nth-child(3) {
  background-color: #ed3f42;
}

.recorder:nth-child(4) {
  background-color: #fee232;
}

.recorder__record {
  position: relative;
  z-index: 1;

  &:active {
    outline: 0;
  }
}

.recorder__pulse {
  @include stretch;
  animation: pulse 600ms infinite ease-in-out;
}

.recorder__beat {
  @include stretch;
  font-weight: bold;
  font-size: 70px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0);
  }
}

.recorder__canvas {
  @include stretch;
}

.recorder__delete {
  position: absolute;
  top: 10px;
  right: 10px;
}

.recorder__delete:hover .icon circle {
  fill: #5b5b5b;
}

.recorder__delete--inactive {
  opacity: 0.2;
  cursor: default;
}