@mixin for-size($size) {
  @if $size == 1024up {
    @media (min-width: 1024px) { @content; }
  } @else if $size == 800up {
    @media (min-width: 800px) { @content; }
  } @else if $size == 600up {
    @media (min-width: 600px) { @content; }
  } @else if $size == 480up {
    @media (min-width: 480px) { @content; }
  } @else if $size == 375up {
    @media (min-width: 375px) { @content; }
  }
}